<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import draggable from "vuedraggable";

export default {
  name: "BoTestimoni",
  extends: Base,
  components: {
    draggable,
  },
  data() {
    return {
      Name: "BoTestimoni",
      mrLevel: {},
      dataLength: 0,
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
    this.moduleRedirect();
  },
  methods: {
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data.data,
          type: "sort",
        },
        (err, resp) => {
          
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "filter.level"(v) {
      if (!v) return;
      this.search();
    },
    "row.at_fullname"(v) {
      this.row.at_alt_photo = v;
      setTimeout(() => {
        $("[name='at_alt_photo']").valid();
      }, 100);
    },
    "row.at_img"(v) {
      if (v.length > 0) {
        $("[error='at_img']").html("");
      }
    }
  },
};
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row gutter-10">
                <div class="col-sm-8">
                  <h5 class="card-title">Daftar {{ ObjectName }}</h5>
                </div>
                <div v-if="dataLength > 0" class="col-9 col-sm-4 col-lg-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="filter.search"
                        v-on:keyup.enter="search"
                        class="form-control"
                        placeholder="Cari..."
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-info"
                          type="button"
                          @click="search()"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataLength > 0" class="col-3 col-sm-auto">
                  <router-link :to="{ name: Name }" class="btn btn-warning"
                    >Reset</router-link
                  >
                </div>
              </div>
            </VForm>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    {{ fields.at_fullname }}
                  </th>
                  <th>{{ fields.at_occupation }}</th>
                  <th>{{ fields.at_is_active }}</th>
                  <th
                    width="120px"
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    Aksi
                  </th>
                </tr>
              </thead>
              <draggable
                v-if="moduleRole('Edit')"
                v-model="data.data"
                tag="tbody"
                @end="endDrag"
              >
                <tr v-for="(v, k) in data.data" :key="k" style="cursor: move;">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.at_fullname }}</td>
                  <td>{{ v.at_occupation }}</td>
                  <td>
                    <StatusLabel :status="v.status"></StatusLabel>
                  </td>
                  <td
                    class="btn-action"
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    <router-link
                      v-if="page.moduleRole('Edit')"
                      class="icon_action"
                      :to="{ name: Name, params: { id: v.id } }"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></router-link>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action"
                      @click="changeStatus(k)"
                      v-tooltip="'Ubah Status'"
                      ><i class="icon-settings"></i
                    ></a>
                    <a
                      v-if="page.moduleRole('Delete')"
                      href="javascript:;"
                      class="icon_action"
                      @click="deleteItem($event, k)"
                      v-tooltip="'Hapus'"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
                <tr v-if="data.data === false">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </draggable>

              <tbody v-else>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.at_fullname }}</td>
                  <td>{{ v.at_occupation }}</td>
                  <td>
                    <StatusLabel :status="v.status"></StatusLabel>
                  </td>
                  <td
                    class="btn-action"
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    <router-link
                      v-if="page.moduleRole('Edit')"
                      class="icon_action"
                      :to="{ name: Name, params: { id: v.id } }"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></router-link>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action"
                      @click="changeStatus(k)"
                      v-tooltip="'Ubah Status'"
                      ><i class="icon-settings"></i
                    ></a>
                    <a
                      v-if="page.moduleRole('Delete')"
                      href="javascript:;"
                      class="icon_action"
                      @click="deleteItem($event, k)"
                      v-tooltip="'Hapus'"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
                <tr v-if="data.data === false">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            class="justify-content-end mt-3 mb-0"
            :data="data"
            :limit="3"
            @pagination-change-page="onPaging"
          ></Pagination>
        </div>
      </div>
    </div>

    <!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
    <div class="card" v-if="$route.params.id">
      <VForm @resp="submitForm" method="post">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-8">
              <h5 class="card-title">
                {{ row.id ? "Ubah" : "Tambah" }} {{ ObjectName }}
              </h5>
            </div>
          </div>
          <div class="info"></div>
          <div class="row">
            <div class="col-sm-5">
              <BoField 
                name="at_fullname" 
                v-model="row.at_fullname" 
                v-bind="validation('at_fullname')"
                :attr="{placeholder: 'e.g. Galih Kusuma'}"
              ></BoField>
              <BoField
                name="at_occupation"
                v-model="row.at_occupation"
                v-bind="validation('at_occupation')"
                :attr="{placeholder: 'e.g. Wiraswasta'}"
              ></BoField>
            </div>
            <div class="col-sm-4">
              <BoField name="at_testimoni" mandatory :label="'Testimoni'">
                <textarea
                  class="form-control"
                  minlength="10"
                  rows="5"
                  name="at_testimoni"
                  v-model="row.at_testimoni"
                  v-bind="validation('at_testimoni')"
                  placeholder="e.g. Sistem pengajarannya sangat bagus"
                  required=""
                ></textarea>
              </BoField>
              <div class="row">
                <div class="col-7">
                  <BoField name="at_is_active">
                    <radio
                      name="at_is_active"
                      v-model="row.at_is_active"
                      option="Y"
                      :attr="validation('at_is_active')"
                      >Active</radio
                    >
                    <radio
                      name="at_is_active"
                      v-model="row.at_is_active"
                      option="N"
                      >Inactive</radio
                    >
                  </BoField>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <BoField name="at_photo" class="mb-0">
                <Uploader
                  name="at_photo"
                  type="testimoni"
                  uploadType="cropping"
                  :deleted="false"
                  :param="{ thumbnail: true }"
                  v-model="row.at_photo"
                ></Uploader>
              </BoField>
              <BoField
                mandatory
                name="at_alt_photo"
                v-model="row.at_alt_photo"
                v-bind="validation('at_alt_photo')"
                :attr="{
                  type: 'text',
                  placeholder: 'e.g. Galih Kusuma'
                }"
                :label="'Alt Image'"
                required=""
              ></BoField>
            </div>
            <div class="col-12">
              <div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
                <button
                  type="submit"
                  class="fcbtn btn btn-info btn-outline btn-rounded btn-loading"
                >
                  {{ $route.params.id == "add" ? "Tambah" : "Ubah" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>
  </div>
</template>
